import { useState } from "react";
import "./App.css";
import logo from "./assets/logo.png";
import mock1 from "./assets/Mock1.png";
import mock2 from "./assets/Mock2.png";
import mock3 from "./assets/Mock3.png";
import rec from "./assets/Rectangle.png";
import txt from "./assets/txt.png";

function App() {
  const [err, setErr] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [showOTP, setShowOTP] = useState(false);

  return (
    <>
      {termOpen === true ? (
        <div className="bg-black w-screen h-screen overflow-x-hidden">
          <button
            className="text-white p-10 fixed"
            onClick={() => setTermOpen(false)}
          >
            X
          </button>
          <div className="text-white mx-20 mb-10 mt-[120px]">
            <h1 className="mt-5 font-bold">Terms of Use</h1>{" "}
            <p>2023/06/20 07:04:48</p>{" "}
            <p>
              Exonyx (www.Exonyx.email) is an information intermediary service
              Platform (hereinafter referred to as the “Platform") that provides
              Users with digital asset trading and related services. The
              Platform provides services to Users registered with the Platform
              (hereinafter referred to as the “Users") in accordance with the
              terms and conditions of this Agreement (defined below), and this
              Agreement shall be legally binding between the Users and the
              Platform. The Platform hereby reminds the Users to carefully read
              and fully understand the terms and conditions of this Agreement,
              especially those terms and conditions of this Agreement that
              exclude or limit the liability of the Platform and exclude or
              restrict the rights and interests of the Users. The Users shall
              read carefully and choose to accept or reject this Agreement.
              Unless a User accepts all the terms and conditions of this
              Agreement, the User shall not be entitled to use the services
              provided by the Platform. If the User does not agree to the
              content of this Agreement or refuses to recognize the right of the
              Platform to make unilateral amendments to this Agreement at any
              time, the User shall promptly stop using and cease to access the
              Platform. By registering as a User of the Platform or using the
              services offered, a User is deemed to fully understand and fully
              accept all the terms and conditions of this Agreement, including
              any amendments that the Platform may make to this Agreement at any
              time.
            </p>{" "}
            <p>
              For the convenience of wording in this Agreement, the Platform is
              collectively referred to as "we" or other applicable forms of
              first-person pronouns in this Agreement. All natural persons and
              other visitors who log onto this Website shall be referred to as
              "you" or any other applicable forms of the second-person pronouns.
              You and we are collectively referred to as “both parties”, and
              individually as “one party” herein.
            </p>{" "}
            <h2>Chapter 1 Definition and Interpretation</h2>{" "}
            <p>
              Article 1 In this Agreement, the following terms and expressions
              shall have the meanings ascribed to them below, unless any term or
              condition herein requires otherwise:
            </p>{" "}
            <p>
              (1) Agreement: consists of this Service Agreement, the Privacy
              Policy, Rules against Money Laundering and Terrorism Financing, as
              well as any other rules, statements, and guidelines inter alia
              that have been or may be released or published on the Platform.
            </p>{" "}
            <p>
              (2) Force Majeure: includes maintenance of information network
              equipment, failure of access to information networks, failures of
              computer, communication or other systems, power failures, weather,
              accidents, industrial actions, labor disputes, riots,
              insurrections, disturbances, inadequacy in productivity or means
              of production, fires, floods, storms, explosions, wars, or other
              factors on the part of cooperation partners, collapse of the
              digital asset market, government actions, judicial or
              administrative orders, and other circumstances that are beyond the
              control of the Platform.
            </p>{" "}
            <p>
              (3) Affiliate(s): if a company directly or indirectly controls
              another company, or is directly or indirectly under the control of
              another company, or otherwise has significant influence over
              another company or is under the significant influence of another
              company, the former is then an affiliated company of the latter;
            </p>{" "}
            <p>
              (4) Intellectual Property Rights: shall have the meaning ascribed
              to it under Article 88 of this Agreement.
            </p>{" "}
            <p>
              Article 2 All codes or statutes or administrative regulations
              cited in this Agreement shall refer to the latest amended version
              thereof, regardless of whether such amendment is made before or
              after the signing of this Agreement.
            </p>{" "}
            <p>
              Article 3 The headings of the terms and conditions of this
              Agreement are for convenience only and shall not be used for the
              purpose of interpreting the terms and conditions of this
              Agreement. References herein to any statement, term, condition,
              annex, schedule shall refer to statements, terms, conditions,
              annexes, and schedules hereunder.
            </p>{" "}
            <p>
              Article 4 The term “include” used herein shall, under any and all
              circumstances, always have the meaning ascribed to the term unless
              this Agreement requires otherwise.
            </p>{" "}
            <p>
              Article 5 Unless it is agreed otherwise herein, should there be
              any conflict or inconsistency between the documents hereunder, the
              validity of the documents shall be prioritized in the following
              order, so as to resolve such conflict or inconsistency:
            </p>{" "}
            <p>(1) User Service Agreement of Exonyx Website;</p>{" "}
            <p>(2) Privacy Policy of Exonyx Website;</p>{" "}
            <p>(3) Rules against Money Laundering and Terrorism Financing;</p>{" "}
            <p>
              (4) other agreements, rules, and guidelines as may be implemented
              from time to time.
            </p>{" "}
            <p>
              Article 6 The Users may choose from among different language
              versions of the Platform. Should there be any inconsistency or
              conflict between the different language versions in terms of
              content, or should there be any omission in one language version,
              the English version of the Platform (and the corresponding terms,
              agreements, policies, etc., as indicatedunder Article 5) shall
              prevail.
            </p>
            <h1 className="mt-5 font-bold">
              Chapter 2 Basic Terms of the Platform
            </h1>{" "}
            <p>
              Article 7: The Platform is an information intermediary service
              platform (website: www.Exonyx.email; if the Platform releases a
              new web address in the form of an announcement, please visit the
              new web address). The Platform services are provided by this
              Company to its Users through various means, such as the Platform
              and clients. The specific content of the services mainly includes
              the release of digital asset trading information, digital asset
              trading services, User services, and other transaction
              facilitation services, subject to the content of the services
              actually provided by the Platform.
            </p>{" "}
            <p>
              Article 8: In order to protect your rights and interests, you
              should carefully read and fully understand all the terms and
              conditions of this User Service Agreement before you voluntarily
              register to use the Platform services. Upon your registration with
              the Platform or use of the Platform, it shall be deemed that you
              fully understand and accept this Agreement and any and all
              subsequent amendments that the Platform may make thereto at any
              time. If you breach this Agreement, you shall be held
              contractually responsible for any and all legal consequences of
              such breach.
            </p>{" "}
            <p>
              Article 9: The Platform may amend this Agreement at any time. Upon
              any change to the content of this Agreement, the Platform will
              release the latest version of this User Service Agreement, as
              amended, without notifying each User one by one. If a User does
              not agree with the amendment of this Agreement made by the
              Platform, the User has the right to stop using the Platform
              services. If the User continues to use the Platform services, it
              is deemed that the User accepts the amendment(s) made by the
              Platform to this Agreement and the User will adhere to the terms
              and conditions of this Agreement as amended.
            </p>{" "}
            <p>
              Article 10: As for the Platform's notices to the Users, and any
              other agreements, announcements, or other notices in connection
              with the Users' use of their accounts and services, the Users
              agree that the Platform may deliver such notices, agreements, and
              announcements by such electronic means as intra-Platform
              announcements, intra-website messages, e-mails, mobile phone short
              text messages, wireless communication devices, inter alia, or by
              such physical means as by post. Such notices, agreements, and
              announcements shall be deemed to have been duly served on the
              addressees on the day when they are delivered (if they are sent to
              the Users by post, they shall be deemed to have been served on the
              third natural day after they are posted to the contact addresses
              of the Users retained on the Platform). If a User fails to receive
              any of the aforesaid notices, agreements, or announcements on the
              date when it is deemed to have been served due to reasons not
              attributable to the Platform (including inaccurate or invalid
              e-mail address, mobile phone number, contact address, inter alia),
              the Platform shall not be held responsible in any manner
              whatsoever.
            </p>{" "}
            <h1 className="mt-5 font-bold">Chapter 3 Registered Users</h1>{" "}
            <p>
              Article 11: Users must register with the Platform before they may
              access the Platform services.
            </p>
            Share Retry Article 12 The Users who are individuals shall be
            natural persons who are at least 18 years of age, or are deemed
            under the law of the country where they are located as natural
            persons having full capacities for civil rights and are capable of
            independently bearing civil liabilities. Article 13 A legal person,
            organization or any other institution that intends to register as an
            institutional User with the Platform as a User thereof shall
            designate a representative who is a natural person that is at least
            18 years of age or is deemed under the law of the country where such
            natural person is located as having full capacities for civil rights
            and capable of independently bearing civil liabilities to complete
            the registration with the Platform on behalf of such legal person,
            organization or institution. Article 14 Upon your clicking the
            "agree to register" button, you or the organization you are duly
            authorized to represent shall be deemed as having agreed to the
            entire content of this Agreement, and you or the organization you
            represent shall be bound by this Agreement. If you do not have any
            of the qualifications required under Article 12 or Article 13 of
            this Agreement, then you and the institution that you are authorized
            to represent shall bear any and all the consequences resulting
            therefrom, and the Platform reserves the right to cancel or
            permanently freeze your account and to hold you and the institution
            that you are authorized to represent accountable. Article 15 You
            agree to provide such information as your name, email address,
            mobile phone number, nationality, Identity (“ID”) card number inter
            alia, as is required by the Platform’s User registration page.
            Article 16 Before or after a User’s registration with the Platform,
            the Platform shall have the right to require the User to provide
            more information or data in accordance with the requirements of any
            of the applicable laws, regulations, rules, orders and other
            regulatory documents of the country or region where the User is
            located. The User should cooperate with the Platform by and shall be
            responsible for, submitting the requisite information or materials
            and adopting reasonable measures to meet the requirements of local
            regulatory requirements. Article 17 Each User shall hereby make the
            following undertakings: (1) the User registers with the Platform and
            uses the Platform for the purpose of legally trading the User’s own
            digital assets, and does not have any intention to use the Platform
            to violate any law or regulation or undermine the trading order of
            digital assets; (2) the User must provide truthful, up-to-date,
            valid and complete information as is required by the Platform; (3)
            the User must guarantee and undertake that the funds the User uses
            in trading through the Platform are from legal sources and has not
            been derived from any illegal activities or means; (4) the User has
            the obligation to maintain and update the User profile and ensure
            that it is true, up-to-date, valid and complete; (5) the User
            (whether as an individual or legal entity) is not a resident of or
            registered in, any of the Restricted Locations. For the purpose of
            this Agreement, “Restricted Locations” shall include the United
            States(including US territories, such as Puerto Rico, Guam, the
            Northern Mariana Islands, American Samoa, and etc.), Singapore, the
            mainland of China and Hong Kong, Thailand, Malaysia, Ontario Canada
            (the list of “Restricted Locations” may be updated from time to time
            at the Platform’s sole and absolute discretion) ; (6) the User
            (whether as an individual or legal entity) is not on any sanction
            lists of competent authority, including but not limited to the
            sanction lists of the Republic of Seychelles, the sanction lists of
            international laws and conventions to which the Republic of
            Seychelles is a party and other applicable sanction lists. (7)
            access to the Platform or utilization of services thereof does not
            breach any applicable law or regulation in the jurisdiction in which
            the User is residing (any laws restricting the sale, purchase,
            holding, or dealing with, digital assets). (8) in addition to this
            Agreement, the User shall also comply with all rules issued and
            updated by the Platform from time to time, including announcements,
            product flow descriptions, Platform project descriptions, risk
            alerts, inter alia. Article 18 Unless the information submitted by a
            User is obviously false, wrong and incomplete, the Platform has the
            right to rely on the information provided by the User. Article 19 If
            the User violates any of his/her/its undertakings under Article 17
            of this Agreement: (1) the Platform has the right to adopt such
            measures as suspending the User’s account with the Platform and
            refusing to allow the User to use part or all of the functions of
            the Platform services (including access to the User’s digital
            assets). In this case, the Platform shall not be held responsible in
            any manner whatsoever and the User agrees to bear any and all direct
            or indirect expenses or losses arising therefrom; (2) if the
            Platform services cannot be provided or any error occurs in the
            provision of such services due to the User’s failure to update
            his/her/its profile or provide the necessary information requested
            by the Platform, the User may not use it as an excuse for cancelling
            a transaction or refusing to pay, and the Platform shall not bear
            any responsibility whatsoever; and all consequences shall be borne
            exclusively by the User; (3) the User shall bear any and all direct
            or indirect losses and adverse consequences arising from the User’s
            breach of any of the undertakings; any and all illicit gains that
            the User may gain from such breach shall be deducted, and the
            Platform reserves the right to hold Users accountable. Article 20
            After you legally, completely, and effectively provide all the
            necessary information for registration and such information is duly
            verified, the registration process is completed, upon which, you
            officially become a User of the Platform and can log into the
            Platform as a User thereof. Article 21 The Platform shall have the
            right to terminate the registration of a User as such or terminate
            the User’s access to his/her/its account with the Platform (in its
            sole and absolute discretion) if the Platform finds out that the
            User is not suitable for high-risk investment and/or if the Platform
            is of the view (in its sole and absolute discretion) that the User
            has breached any of the terms of this Agreement. Article 22
            Notwithstanding the other terms and conditions of this Agreement,
            the Platform has the discretion to determine whether a User can pass
            the Platform User authentication and whether to cancel the
            registration of the User who has already been authenticated. The
            Platform has the right to refuse to permit or cancel the
            registration of any User, and has no obligation to inform the User
            of the reason for rejecting the registration thereof. The Platform
            does not bear any direct or indirect losses suffered by the User due
            to the Platform's refusal to permit the registration of such User,
            and the Platform reserves the right to hold the User accountable.
            Article 23 Users register as Users of the Platform on the basis of
            their free will. The Platform does not force, induce, deceive, or
            otherwise exert influence on them in an unfair manner, so that they
            would register with the Platform.{" "}
            <h1 className="mt-5 font-bold">
              Chapter 4 Content of Platform services for Registered Users
            </h1>{" "}
            Article 24 The Platform provides the following services to Users who
            have completed their registration with the Platform: (1) relevant
            information disclosed by digital assets projects; (2) real-time
            quotation and trading information of various digital assets
            projects; (3) digital asset trading services; (4) customer services;
            (5) technical and management services ensuring the normal operation
            of the Platform; (6) other services publicly announced by the
            Platform. Article 25 The Platform, as is entrusted by digital asset
            project owners, releases information related to and provides
            matching services for transactions in digital assets in accordance
            with Article 24 of this Agreement. The Platform is only responsible
            for reviewing the text of information released by the digital asset
            project owners, and does not guarantee or assume any responsibility
            for the accuracy, completeness or legality of such information. The
            Users shall make decisions based on their independent judgment and
            shall engage their own advisors and/or conduct their own research.
            If Users conduct digital asset transactions based on such
            information, the risks arising therefrom shall be borne exclusively
            by the Users themselves, and the Users have no right to propose any
            legal claim to the Platform on the basis of such risks. Any dispute
            between a User and the digital asset project owner arising from or
            related to transaction shall be settled by and between the parties
            to the dispute themselves, and the Platform shall not bear any
            transaction risk or legal liability whatsoever. Article 26 The
            digital asset trading services mentioned under Article 24 of this
            Agreement shall include the following: (1) User Accounts: a User
            account will be generated upon a User’s registration with the
            Platform. The User account will record the User's activities on the
            Platform. The above-mentioned User account is the only account for
            the User to log on to the Platform. (2) Digital asset trading: Users
            can submit digital asset trading instructions through the Platform
            and trade other digital assets with digital assets in their User
            accounts. (3) Digital assets deposit and withdrawal: a User can
            transfer digital assets from other addresses to designated addresses
            in the User’s account, or transfer digital assets from the User’s
            account to other addresses. (4) Updating trading status: the User
            confirms that the digital asset trading status confirmed by the User
            in accordance with the Platform services procedures on the Platform
            will become an irrevocable instruction for the Platform to conduct
            relevant transactions or operations for the User. The User agrees
            that the execution time of relevant instructions shall be based on
            the actual operation time of the Platform in the Platform system.
            The User agrees that the Platform has the right to handle relevant
            matters in accordance with this Agreement and/or the relevant
            dispute resolution rules of the Platform. Any dispute or loss caused
            by User’s failure to amend or confirm the transaction status, or to
            submit relevant applications in a timely manner, shall be borne by
            the User exclusively, and the Platform shall not bear any
            responsibility whatsoever. (5) Transmission of trading instructions:
            the Platform transmits the User’s trading instructions at the time
            the User inputs the trading instructions and data. The User
            understands that the Platform is the matchmaker of digital asset
            transactions, not a buyer or seller participating in the transaction
            of digital asset transactions itself, and that the Platform does not
            provide any services relating to the deposit or withdrawal of the
            legal tender of any country. (6) Transaction inquiry: the Platform
            will record all of the User's operations on the Platform, regardless
            of whether the purpose of such operations is finally achieved. The
            User can query such transaction records under the User’s account in
            real time via such User’s account. (7) Transaction security
            settings: the Platform has the right to carry out the settings of
            transaction-related matters from time to time based on
            considerations of such factors as transaction security, including
            transaction limits and transaction times, inter alia. The Users
            understand that the aforesaid settings of the Platform may cause
            certain inconveniences in trading, and the Users have no objection
            thereto. (8) Handling of system failures: if the Platform uncovers a
            handling error caused by a system failure or any other reason, the
            Platform has the right to correct the error regardless of whether it
            is beneficial to the Platform or its Users. Where due to such error,
            the User actually receives more digital assets than the amount that
            such User should have received, then regardless of the nature and
            reason of the error, the Platform reserves the right to correct such
            improperly executed transaction and the User shall return the
            overcharged digital assets or perform other operations in accordance
            with the specific requirements of the Platform's notice to the User
            regarding the correction of such error. The User understands and
            agrees that the Platform will not assume any losses or
            responsibilities caused by the aforesaid handling error. Article 27
            Except for the services listed under Article 24 of this Agreement
            and the technical services announced by the Platform, the Platform
            cannot provide any investment, legal, taxation or other professional
            opinions to the Users in connection with digital asset transactions.
            Moreover, any information, discussion, analysis, price and other
            information provided by any Platform are general comments and do not
            amount to advice to the Users in connection with any digital asset
            transaction. A User that needs any professional advice should
            consult relevant professionals for professional advice on
            investment, law, taxation or other professional advice related to
            digital cash transactions. The Platform does not assume any direct
            or indirect losses (including any loss of profits) caused by a
            User's reliance on the above-mentioned general comments. Article 28
            The services provided by the Platform shall not be understood or
            used to make offers to Users in any country or region that
            determines that the services provided by the Platform are illegal.
            Article 29 Users shall observe the following trading rules in the
            process of trading digital assets using this Platform: (1) Browsing
            trading information: when browsing trading information on the
            Platform, the Users should carefully read all the content in the
            trading information, including price, entrusted amount, handling
            fees and buying or selling direction. The Users should click on the
            button to conduct transaction after fully understanding and
            accepting all the content in the trading information. (2) Submission
            of entrustment: after understanding and completely receiving all the
            content of the trading information, Users can enter the digital
            asset trading information and submit the transaction entrustment
            after confirming that the information is correct. Upon submission of
            the transaction authorization to the Platform, the User shall be
            deemed as having authorized the Platform to act as an agent for the
            User to carry out corresponding transaction matching in accordance
            with the digital asset trading information input by the User. The
            User acknowledges and agrees that when there is a digital asset
            transaction that meets the User's entrusted transaction price, the
            Platform will automatically complete the transaction matching
            without notifying Users in advance. (3) Inquiry of transaction
            details: a User can view the corresponding transaction records of
            digital assets through the transaction details in the personal
            account of such User. (4) Revocation or amendment of entrustment:
            the User acknowledges that the User has the right to revoke or amend
            the entrustment at any time before the digital asset transaction
            matching is completed. Article 30 The Platform has the right to
            amend, suspend or permanently terminate some or all of the services
            the Platform provides to a User for any of the following reasons:
            (1) as is required by any of the laws, regulations, rules and orders
            of the sovereign country or region where the User is based; (2) as
            may be necessary for the Platform to protect the legitimate
            interests of the Platform or customers thereof; (3) there is any
            change to the trading rules of digital assets; (4) any other
            justifiable reason. Article 31 If the Platform modifies, suspends or
            permanently terminates some or all of the services that the Platform
            offers to a User based on Article 30 of this Agreement, the
            effective date of such modification, suspension or termination shall
            be subject to the Platform’s announcement.{" "}
            <h1 className="mt-5 font-bold">Chapter 5 Fiat Services</h1>
            Article 32 The Users understand and agree that: (1) the Platform has
            engaged several payment service providers(such as UAB Eurlita),
            liquidity providers and market makers as authorized service
            providers to provide fiat-to-digital assets exchange services
            (depending on the Users jurisdiction and the fiat currency with the
            User may elect to purchase digital assets) the (respectively, the
            “Exchange Service Providers” and the “Fiat Services”); (2) the
            Exchange Service Providers never facilitate any service other than
            the Fiat Services; (3) the Platform shall have no obligation or
            responsibility to the User as to the Fiat Services; (4) the
            utilization of the Fiat Services shall be subject to the respective
            terms and conditions of the Exchange Service Providers and governed
            in the respective jurisdiction of the Exchange Service Providers;
            (5) the Platform retains the right to suspend/restrict/instruct the
            Exchange Service Providers to restrict any Fiat Services or impose
            any other measures necessary (including, without limitation, the
            clawing back of funds for any outstanding fees owed to the
            Platform), if the Users breach any of the terms in this Agreement or
            such other terms and conditions that may be implemented on the
            Platform.{" "}
            <h1 className="mt-5 font-bold">
              Chapter 6 Account Security and Management
            </h1>
            Article 33 The Users understand and agree that it is the
            responsibility of the Users to ensure the confidentiality and
            security of their accounts and passwords. The Users will assume full
            responsibility for all actions and statements made using the Users’
            accounts and passwords and agree to the following: (1) Users should
            create passwords in accordance with relevant rules of the Platform
            and relevant prompts of the Platform (passwords include but are not
            limited to login passwords, fund passwords, mobile phone numbers
            bound when registering accounts, mobile phone verification codes
            received via mobile phones, Google verification, inter alia.
            Specific forms thereof may change; the same hereinafter. They should
            avoid choosing overly obvious words or dates as their passwords,
            such as Users' names, nicknames, birthdays, inter alia. (2) The
            Users shall not disclose their accounts or passwords to any other
            person, nor shall they use the accounts or passwords of any other
            person. If the account of a User is illegally used by any other
            person due to factors not attributable to the Platform, e.g,
            hacking, virus or negligence on the part of the User, the Platform
            will not assume any responsibility whatsoever; (3) the Users are
            prohibited from giving, lending, renting out, transferring or
            otherwise disposing of the Platform account to any third party
            without the consent of the Platform; (4) the Platform recognizes the
            Users’ instructions through the Users’ accounts and passwords. The
            Users hereby confirm that all their conduct on the Platform after
            they log into the Platform using their accounts and passwords shall
            represent the Users themselves. The electronic information records
            generated by the operation of the Users’ accounts are all valid
            evidence of the Users’ conduct, and the Users shall bear any and all
            the responsibilities arising therefrom. (5) The Users shall adopt
            appropriate measures to ensure the security of their accounts and
            passwords after the Platform notifies the Users of foreseeable
            security risk. (6) Where any person uses the account and password of
            a User without due authorization, the Platform and the legally
            authorized subject reserve the right to hold the actual User jointly
            and severally liable. Article 34 If a User discovers that a third
            person fraudulently uses or embezzles the User's account and
            password, or such third person’s use of the User’s account involves
            any absence of requisite and due authorization, the User shall
            promptly notify the Platform in an effective manner and request the
            Platform to suspend relevant services; otherwise all the
            responsibilities arising from such use shall be borne by the User
            exclusively. Furthermore, the User understands that the Platform
            needs a reasonable period of time to take action on the User's
            request; the Platform shall not be held liable for any loss that may
            arise in connection with such third person’s use of the services
            before the Platform takes action. Article 35 Where the Platform
            deems on its unilateral and independent judgement that any event
            that undermines the security of trading may arise, the Platform
            shall have the right to suspend, interrupt or terminate all or part
            of the User services (including fee-based services) provided to a
            User under this Agreement, remove or delete registration information
            of such a User, seize illicit profits that the User may gain,
            without notifying such User and without assuming any responsibility
            to such User or any third party. The aforementioned events include:
            (1) the Platform believes that the information provided by the User
            is not authentic, valid or complete, e.g, where the User registers
            with the Platform on the basis of identity information of any other
            person that the User uses without due authorization, or the
            information provided by the User for verification is inconsistent
            with relevant facts; (2) the Platform uncovers any abnormal
            transaction by the User or any transaction by the User is suspicious
            or may be illegal; (3) the Platform believes that the User’s account
            is suspected of being involved in money laundering, cash-out,
            pyramid selling, fraudulent use or other situations that the
            Platform believes are risky or unlawful; (4) the Platform discovers
            that the User uses any illegal or improper technical means to engage
            in any activity that endangers the security of trading or affects
            fair trading, including tampering with transaction data, stealing
            customer information, stealing transaction data, attacking other
            registered accounts through the Platform, inter alia; (5) the
            Platform believes that the User has violated any of the rules under
            this Agreement or the spirit thereof; (6) the User account has not
            been logged in or actually used for one year in a row, or the amount
            of digital assets in the account is zero; (7) any other
            circumstances under which the User breaches this Agreement; (8)
            other circumstances under which the Platform, based on its sole
            judgment, needs to suspend, interrupt or terminate all or part of
            the User services (including fee-based services) provided to Users
            under this Agreement and remove or delete the registration
            information on the ground of transaction security and other reasons.
            Article 36 When a User decides to cease to use his/her/its User
            account, the User shall first pay off all outstanding payables
            (including service fees, inter alia), then withdraw all available
            digital assets (if any) from the User account that are eligible for
            withdrawal, apply to the Platform for freezing the User account, and
            formally cancel the User account upon approval by the Platform.
            Article 37 The User agrees that if the identity verification
            procedure for his/her/its User account fails to be completed, and
            the account fails to be logged into for a year in a row, the
            Platform has the right to terminate the supply of User account
            services without prior notice to the User, and the Platform may
            promptly suspend, close or delete the User account and all relevant
            materials and files in the User account. Article 38 The User agrees
            that the suspension, interruption or termination of the User's
            account does not represent the termination of the User's
            responsibilities. The User shall still be liable for any possible
            breach of agreement or damages that may arise due to or in
            connection with such User’s conduct during the time when such User
            uses the services provided by the Platform; furthermore, the
            Platform may continue keeping relevant information of the User.
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 7 Prohibited Businesses{" "}
            </h1>
            Article 39 The Users undertake that they will never use the Platform
            services for any illegal purpose or in any illegal way, and
            undertake to abide by the relevant laws and regulations of the
            country where they are located, as well as all international
            practices relating to the use of the Internet, and to abide by all
            network protocols, rules and procedures related to the Platform
            services. Article 40 The Users agree and guarantee that they will
            not use the Platform services to engage in any infringement of the
            rights and interests of any other person or for any illegal conduct,
            and they shall bear any and all legal liabilities if they breach
            such guarantee. The above-mentioned infringements and conduct
            include: (1) accessing the Platform services in the name of any
            other person without being duly authorized by such person; (2)
            engaging in any illegal transaction, such as trafficking of
            firearms, narcotics, forbidden drugs, pirated software or other
            prohibited items; (3) providing gambling information or inducing in
            any manner any other person to engage in gambling; (4) engaging in
            suspected money laundering, cash-out or pyramid selling activities;
            (5) engaging in any conduct that may result in vulnerability to
            computer virus or may damage the Platform services system or data
            therein; (6) using the Platform services system to engage in any
            activity that may adversely affect the normal operation of the
            Internet or mobile computer network; (7) maliciously interfering
            with the normal proceeding of digital asset transaction and
            disrupting the order of digital asset trading; (8) using any
            technical means or other means to interfere with the normal
            operation of the Platform or interfering with the use of Platform
            services by any other User; (9) maliciously defaming the goodwill of
            the Platform by fabrication or exaggeration; (10) any other conduct
            that is justifiably deemed by the Platform as inappropriate. Article
            41 The Platform reserves the right to delete all types of
            information of a User in the Platform that does not conform to legal
            policies or is untrue or inappropriate on the basis of the
            independent judgement by the Platform, without notifying the User
            and without assuming any responsibility. If the User fails to comply
            with the above provisions, the Platform has the right to take
            measures such as suspending or closing the User’ account on the
            basis of its own independent judgement and without assuming any
            responsibility. Article 42 The User agrees that if any third party
            initiates or launches any claim or demand for compensation
            (including attorney fees) on the ground that the User breaches this
            Agreement or violates any document that is incorporated into this
            Agreement by reference and becomes a part of this Agreement, or that
            the User’s use of the Platform services violates any law or
            infringes on any right of the third Party, the User will indemnify
            and hold harmless the Platform and affiliated parties thereof,
            cooperation partners, directors and employees thereof against such
            claim or demand. Article 43 The User undertakes that the information
            uploaded or released by the User through the Platform is authentic
            and valid, and any and all the information the User submits to the
            Platform is authentic, valid, complete, detailed and accurate. If
            the Platform or any other User of the Platform suffers any loss due
            to the User’s breach of the above undertakings, the User will assume
            corresponding liabilities. Article 44 The Users understand and agree
            that the Platform provides services to eligible Users. The Platform
            does not assume any responsibility for the investment or trading of
            digital assets on the Platform. The Platform cannot and does not
            have the obligation to ensure the success of the Users’ investment.
            The losses arising from the Users’ investment or transaction of
            digital assets shall be borne by the Users exclusively, and the
            Platform may not be held liable for such loss in any manner
            whatsoever. Article 45 A User agrees to take responsibilities for
            all activities that occur in his/her/its registered account with the
            Platform (including information disclosures, information releases,
            clicks to agree to various agreements, upload and submission of
            various documents, clicks to agree to renew various agreements or
            clicks to agree to digital cash transactions, inter alia), and
            during the above-mentioned activities, if the User fails to comply
            with the terms and conditions of this Agreement or the operating
            instructions in the trading rules published by the Platform, the
            Platform shall not be held liable in any manner whatsoever. Article
            46 The Users agree that the Platform has the right to place various
            commercial advertisements or other commercial information of any
            kind in various ways during the course of providing Platform
            services (including placing advertisements on any page of the
            Platform website), and the Users agree to accept the commercial
            promotions or other relevant commercial information that the
            Platform sends to the Users by email or other means. Article 47 The
            Users agree that if a User has any dispute with a project owner or
            any other third party in connection with any digital asset
            transaction, they shall not request the Platform to provide relevant
            information through channels other than judicial or administrative
            channels.{" "}
            <h1 className="mt-5 font-bold">
              Chapter 8 Service Interruption or Failure
            </h1>{" "}
            Article 48 The Users agree that in view of the unique nature of the
            Internet, the Platform does not guarantee that services will not be
            interrupted, nor does it guarantee the timeliness and/or security of
            the services. If the system is unable to operate normally due to any
            event, as a result of which the Users cannot use any of the Platform
            services or their use of the services is adversely affected, the
            Platform shall not be held responsible to the Users or any third
            party. The aforesaid events include: (1) where the Platform system
            is shut down for maintenance; (2) where there is any error or
            failure in the telecommunication equipment, as a result of which it
            is impossible to transmit data; (3) where the Platform services are
            interrupted or delayed due to such factors as hacker attacks,
            technical adjustments or failures on the party of network service
            providers, or website upgrades, inter alia; (4) where the Platform
            system is unable to function due to force majeure factors such as
            typhoons, earthquakes, tsunamis, floods, power outages, wars,
            terrorist attacks, inter alia.
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 9 Scope and Limitation of Responsibilities{" "}
            </h1>
            Article 49 The Platform does not provide any form of guarantee for
            any Platform services, including the following: (1) Platform
            services will meet the needs of Users; (2) Platform services will be
            provided in a timely manner without any interference or error; (3)
            any products, services, information or other materials purchased or
            obtained by Users through Platform services will meet the
            expectations of the Users; (4) all information, programs, text, data
            and other information contained in the Platform are completely safe
            and free from interference and destruction by any malicious programs
            such as viruses and Trojans; (5) all the calculation results of
            transactions have been duly verified by the Platform; the
            corresponding calculation methods will be publicized on the
            Platform, but the Platform cannot guarantee that there is no error
            or interference in such calculation. Article 50 The Users
            acknowledge and agree that under no circumstance will the Platform
            assume responsibilities for any of the events below: (1) loss of the
            income of Users; (2) loss in the Users’ transaction profits or
            contractual loss; (3) losses arising from interruption, suspension
            or termination of services; (4) losses of expected saved transaction
            cost; (5) losses caused by information transmission problems; (6)
            loss of investment or trading opportunities; (7) loss of goodwill or
            reputation; (8) losses caused by loss of or damage to data; (9) the
            cost of purchasing alternative products or services; (10) any
            indirect, special or incidental losses caused by infringement
            (including intentional infringement and negligence), breach of
            agreement, or any other reason, regardless of whether such losses
            are reasonably foreseeable by the Platform or not, or whether the
            Platform is previously informed of the possibility of such losses.
            Article 51 The Users understand and agree that under no
            circumstances, shall the Platform be required or obliged to
            indemnify the Users for all or part of their losses, including
            (without limitation): (1) where the Platform reasonably believes or
            suspects that Users' conduct on the Platform is illegal or immoral.
            (2) where the Users mistakenly believe that losses are caused by
            factors attributable to the Platform; (3) any other losses caused by
            factors not attributable to the Platform. Article 52 The quality and
            content of services provided by any cooperation partner of the
            Platform services shall be the responsibility of such cooperation
            partner itself. The content of the Platform may involve other
            websites owned, controlled or operated by third parties (hereinafter
            referred to as "Third-party Websites"). The Platform cannot
            guarantee, and has no obligation to guarantee the authenticity and
            validity of any information on the Third-party Websites. The Users
            confirm to use the Third-party Website in accordance with the
            service agreement of the Third-party Websites instead of this
            Agreement. The Third-party Websites are neither recommended nor
            introduced by the Platform. The Users shall judge the content,
            products, advertisements and any other information of the
            Third-party Websites at their discretion and assume the
            corresponding risk on their own, all of which are not related to the
            Platform in any manner whatsoever. The Users shall judge at their
            sole discretion any and all data that they download or obtain by
            using the Platform services an assume relevant risks; any and all
            damage caused by the downloaded data shall be exclusively borne by
            the Users. Article 53 The advice or information obtained by Users
            from the Platform and staff thereof or through Platform services,
            whether written or oral, do not constitute any guarantee for
            Platform services. Article 54 The Platform does not guarantee the
            accuracy, validity, security or integrity of the external links that
            it lists in order to provide convenience to the Users. Furthermore,
            the Platform does not assume any responsibility for the content on
            any web page that such external links may point to and that is not
            actually controlled by the Platform. Article 55 To the extent
            permitted by law, the Platform shall not be held liable in any
            manner whatsoever for any indirect, punitive, special and derivative
            losses (including business losses, loss of profits, loss of use data
            or other economic benefits) in connection with or arising from this
            Agreement, or arising from using the Platform, or from using any of
            the information, content, materials, products (including software)
            and services provided to the Users through the Platform, or from the
            purchase and use of products, regardless of how they arise, and
            regardless whether they arise due to any breach of this Agreement
            (including any breach of the guarantees or undertakings hereunder)
            or infringement. In addition, even if the exclusive relief provided
            in this Agreement does not achieve its basic purpose, the Platform
            shall also be excluded from any liability for the above losses.
            Article 56 Unless this Agreement stipulates otherwise, under any
            circumstances, the total liability of the Platform for breach of
            agreement under this Agreement shall not exceed the total amount of
            service fees charged on the services provided to the Users for the
            current digital asset transaction. Article 57 Unless this Agreement
            stipulates otherwise, under any and all circumstances, if a User
            breaches this Agreement or any of the laws and regulations of the
            country where the User is located, and consequently causes any
            damage to the Platform, the User shall indemnify the Platform
            against any and all direct and/or indirect losses (including
            litigation costs, inter alia). Article 58 The Users recognize that
            common law remedies for breach of agreement or possible breach of
            agreement may not be sufficient to cover all or part of the losses
            suffered by the non-breaching party. Therefore, the Users agree that
            the Platform has the right to seek injunctive remedies and all other
            remedies permitted by common law or equity in the event of breach or
            possible breach of agreement by the other party to this Agreement.
            Article 59 The guarantees and undertakings made by the Platform in
            this Agreement are the only guarantees and representations on the
            basis of which the Platform provides the services under this
            Agreement (hereinafter referred to as "agreement guarantees"), and
            shall supersede all the guarantees and undertakings made in any
            other forms and manners (hereinafter referred to as "non-agreement
            guarantees"), whether the non-agreement guarantees are made in
            writing or orally, explicitly or implicitly. All agreement
            guarantees are exclusively made only by the Platform, and are
            binding on the Platform only, and are not binding on any third
            party. Article 60 Users acknowledge and agree that the Platform does
            not waive any right to which the Platform is entitled and limits,
            exempts or offsets the Platform's liability for damages to the
            maximum extent permitted by law, even if such right is not referred
            to herein.
            <h1 className="mt-5 font-bold"> Chapter 10 Risk Warnings </h1>
            Article 61 Users acknowledge and agree that no transaction through
            the Platform is free from the following risks, and the Platform
            cannot and does not have the obligation to be responsible for the
            following risks: (1) macroeconomic risks: Users may suffer losses
            due to abnormal price fluctuations arising from changes in the
            macroeconomic situation; (2) policy risks: changes in relevant laws,
            regulations, policies and rules may cause abnormal fluctuations in
            prices and other areas, as a result of which the Users may suffer
            losses; (3) default risks: Users may suffer losses due to the
            inability or unwillingness of the project owner to carry out or
            continue carrying out their project; (4) risks related to earnings:
            digital assets are not issued by any financial institution or
            platform, and the digital asset market is a brand new and
            unconfirmed market that may not generate actual increase in
            earnings; (5) trading risks: digital assets are mainly used by
            speculators, and are used relatively less in retailing and
            commercial markets; the trading of digital assets involves an
            extremely high level of risk because trading of digital assets goes
            on uninterrupted throughout the day without any limits on the ups
            and downs, and therefore prices are subject to large fluctuations
            caused by traders and/or global government policies; (6) risks
            caused by force majeure factors; (7) User's fault: any and all
            losses caused by the Users’ fault, including loss caused by wrong
            decision-making, improper operation, forgetting or revealing
            passwords, deciphering of passwords by others, third-party intrusion
            into computer systems used by the Users, and malicious or improper
            operation by a third party entrusted by the Users to serve as their
            agent. Article 62 Digital asset transactions are extremely risky and
            are therefore not suitable investments for most people. The Users
            know and understand that part or all of their investment in digital
            asset trading may be lost, so the Users should determine the amount
            of their investment or transactions based on the extent of losses
            they can afford. In addition to the risks indicated under Article 61
            of this Agreement, there will also be risks that cannot be
            predicted. Therefore, the Users should carefully assess their
            financial situation and various risks before making any decision on
            investment in or transaction of digital assets. The Users shall bear
            any and all losses arising from their decision, and the Platform
            shall not bear any responsibility for Users' investment or trading
            decisions. Article 63 In view of the risks arising from digital
            asset investment or transaction, if a User has any doubts as to such
            investment or transactions, the User should seek the assistance of
            professional consultants prior to transaction or investment. Article
            64 The Platform does not provide any guarantee or condition to any
            User and/or any transaction, whether express, implied or statutory.
            The Platform cannot and does not attempt to control the information
            released by Users or project owners. The Platform does not undertake
            any form of certification and authentication services for such
            information. The Platform cannot fully guarantee the authenticity,
            sufficiency, reliability, accuracy, integrity and validity of all
            content on the Platform, and does not need to bear any legal
            responsibilities arising therefrom. The Users should base their
            transaction on their own independent judgment and assume full
            responsibility for their own judgment. Article 65 The Platform does
            not make any express or implied guarantee for its Users to use the
            Platform services, including but not limited to the applicability,
            absence of errors or omissions, continuity, accuracy, reliability
            and suitability for a particular purpose. Furthermore, the Platform
            does not make any undertaking and guarantee in connection with the
            validity, accuracy, correctness, reliability, quality, stability,
            integrity and timeliness of the technologies and information
            involved in the services provided by the Platform. Article 66
            Whether to log into the Platform or use the services provided by the
            Platform is the personal decision of individual Users, who shall
            exclusively bear any and all risks and possible losses that may
            arise from such decision. The Platform does not make any express or
            implied guarantee in connection with the market, value and price of
            digital assets. The Users know and understand the instability of the
            digital asset market. The price and value of digital assets may
            fluctuate significantly or collapse at any time. Engaging in digital
            asset transaction is the free choice and decision by individual
            Users, who shall exclusively bear the risks and possible losses that
            may arise. Article 67 The above clauses do not reveal all the risks
            and market situations that the Users may be subject to when they
            engage in transactions through the Platform. Before making a
            decision relating to transaction, the Users should fully understand
            the relevant digital assets, adopt a cautious approach to
            decision-making based on their own transaction objectives, risk
            tolerance capacity and asset status, and assume all risks
            exclusively on their own. Article 68 Under any and all
            circumstances, the third party shall be responsible for the services
            that are provided by such third party and are involved in the Users’
            use of the Platform services, and the Platform shall not bear such
            responsibility. Article 69 Any loss or liability caused by factors
            attributable to a User shall be borne by such User exclusively and
            the Platform shall not be held liable in any manner whatsoever.
            Circumstances under which the Platform shall not be held liable
            include: (1) where any loss or liability is caused by the User's
            failure to operate in accordance with this Agreement or any rules
            published by the Platform from time to time; (2) where any loss or
            liability is caused by any uncertainty, ambiguity or incompleteness
            in the instruction information sent by the User to the Platform; (3)
            where any loss or liability is caused by insufficient balance of
            digital assets in the User's account; (4) where any losses or
            responsibilities is caused by any other factor attributable to the
            User.
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 11 Service Fees and Other Fees
            </h1>{" "}
            Article 70 If a User uses the Platform services, the Platform will
            charge relevant Platform service fees on the User. Each item of
            Platform service fee shall be subject to the description and rates
            of fees listed on the Platform when the User uses the Platform
            services. The Platform reserves the right to unilaterally formulate
            and adjust the rates of the Platform services fees. Article 71 The
            Users may have to pay to third parties a certain third-party service
            fee in connection with their use of the Platform services. For the
            specific rates of such third-party service fees, please refer to
            relevant web pages of the Third-party Websites, or the Platform's
            reminders and fee rates. The Users agree to pay such service fees to
            the third parties on their own or by entrusting the Platform or a
            third party designated by the Platform according to the
            aforementioned rates.
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 12 Termination of Agreement{" "}
            </h1>
            Article 72 Users have the right to apply to the Platform for
            cancellation of their accounts with the Platform at any time in
            accordance with the provisions of this Agreement. If the Platform
            cancels the account of a User in accordance with Article 36 of this
            Agreement, this Agreement will be terminated as of the date when the
            Platform approves the User’s application for account cancellation.
            Article 73 Where an account is canceled in accordance with Articles
            36 and 37 of this Agreement, this Agreement will be terminated as of
            the date when the Platform cancels such User account. Article 74 If
            a User deceases or is declared dead, all the rights and obligations
            thereof under this Agreement shall be borne by the successor
            thereof. If a User loses all or part of his/her/its capacity for
            civil rights or civil conducts, the Platform or its authorized
            subject has the right to dispose of the funds related to the User's
            account in accordance with valid legal documents (including
            effective court judgments, inter alia) or instructions from the
            legal guardian of such User. If the successor or legal guardian of
            the User decides to continue performing this Agreement, this
            Agreement shall remain valid; otherwise, the successor or legal
            guardian of the User shall apply to the Platform for cancellation of
            the account number in accordance with Article 35 of this Agreement,
            and this Agreement shall be terminated from the date when the
            Platform approves the cancellation of the account of the User.
            Article 75 The Platform shall have the right to terminate all
            services of the Platform in accordance with this Agreement. This
            Agreement shall terminate on the date of termination of all services
            of the Platform. The withdrawal process shall be operated in
            accordance with the specific provisions of the Platform
            announcement. Article 76 After the termination of this Agreement,
            the Users shall have no right to require the Platform to continue
            providing any services or performing any other obligations to them,
            including but not limited to requiring the Platform to retain or
            disclose to the User any information in their former accounts to
            forward to the Users or any third party any information that they
            have not read or they have sent. Article 77 The termination of this
            Agreement does not affect the non-breaching party's claim against
            the breaching party that the breaching party shall be liable for
            breach of agreement before the termination of other agreements, nor
            does it affect the performance of the post-contractual obligations
            under this Agreement.
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 13 Protection and Authorization of Personal Information{" "}
            </h1>
            Article 78 The personal information under
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 13 of this Agreement shall include the following
              information :
            </h1>{" "}
            (1) personal registration information provided by the Users in
            accordance with the requirements of the Platform when they register
            accounts with the Platform or when they use the accounts, including
            but not limited to telephone numbers, mailbox information, and ID
            card information; (2) the server data in the Users’ browser that the
            Platform automatically receives and records when the Users use the
            Platform or access the Platform, including but not limited to IP
            address and other data and web page records required by the Users;
            (3) relevant data collected by the Platform on which Users conduct
            transactions on the Platform, including but not limited to
            transaction records; (4) personal information of other Users legally
            obtained by the Platform. Article 79 Without additional consent from
            the Users, the successful registration of the Users on the Platform
            shall be deemed as the Users’ consent that the Platform may collect,
            use or disclose the Users’ personal information, and the Users
            understand and agree that the Platform may use the collected
            personal information of the Users for the following purposes based
            on the consideration of customizing the Platform services for the
            User, resolving disputes and helping to ensure safe transactions on
            the Platform: (1) providing Platform services to Users; (2)
            reporting to relevant departments based on the requirements of
            relevant competent departments of sovereign countries or regions;
            (3) when the Users use the Platform services, the Platform will use
            the Users’ information for legal purposes, such as authentication,
            customer service, security & prevention, fraud monitoring,
            marketing, archiving and backup, or for cooperation with third
            parties to promote websites to ensure the security of products and
            services provided by the Platform to the Users; (4) to assist the
            Platform in designing new products and services and in collecting
            and sorting out information to improve the existing service
            objectives of the Platform; (5) in order to ensure that the Users
            understand the specific conditions of the Platform services, Users
            agree that the Platform will send them marketing campaign notices,
            commercial electronic information and advertising related to the
            Users, instead of generally placed advertisements; (6) the Platform
            transfers or discloses the Users’ information to any unrelated third
            party in order to complete merger, demerger, acquisition or asset
            transfer; (7) to complete software certification or management
            software upgrade; (8) to invite Users to participate in surveys
            about relevant Platform services; (9) to be used in data analysis
            for cooperation with government agencies, public affairs agencies,
            associations, inter alia; (10) to be used to resolve disputes or
            mediate in disputes; (11) to be used for all other legitimate
            purposes and other purposes authorized by the Users. Article 80 The
            Platform automatically tracks certain information on Users in
            connection with their conduct on the Platform. On the precondition
            of not disclosing the Users’ privacy, the Platform has the right to
            analyze the entire User database and make commercial use of the User
            database. Article 81 The Users agree that the Platform can use data
            collection devices such as "cookies" on some web pages of the
            Platform. Article 82 The Platform shall protect the Users' data in
            accordance with relevant laws and regulations. The information
            provided to the Platform by the Users in connection with the
            performance of this Agreement may not be sold maliciously or shared
            with any third party free of charge, except for under the following
            circumstances: (1) suppliers that provide independent services and
            only require information related to and necessary for the services;
            (2) government departments or other institutions that have legal
            access to information and retrieve information through legal
            channels; (3) affiliated companies of the Platform; (4) third
            parties approved by the Platform Users or the authorized
            representative of the Platform Users. Article 83 The User shall
            authorize the Platform, unless the law requires otherwise, to use
            the information provided by Users to the Platform, the information
            generated through using the Platform’s services (including the
            information provided and generated prior to the signing of this
            Agreement) and the information queried and collected by the Platform
            in accordance with this Article, to provide services, recommend
            products, carry out market research and information data analysis
            for Users by the Platform and its partners entrusted in connection
            with the services. Article 84 Unless the law requires otherwise, the
            User shall authorize the Platform, for the purpose of providing
            better services and products to the Users, to inquire and collect
            User information and provide such information to partners with which
            the Platform cooperates as may be necessary for the services.
            Article 85 In order to ensure the safety of User information, the
            Platform and its partners are obligated to keep confidential the
            above information and take various measures to ensure the safety of
            the information. Article 86 The provisions under{" "}
            <h1 className="mt-5 font-bold">
              chapter 13 of this Agreement shall come into effect upon the
              signing of this Agreement, have independent legal effect, and may
              not be affected by the formation of the contract or the change of
              its effective status.
            </h1>
            <h1 className="mt-5 font-bold">
              {" "}
              Chapter 14 Protection of Intellectual Property{" "}
            </h1>
            Article 87 Intellectual property rights, including trademark rights,
            patent rights, copyrights, trade secrets, and so on, to all the
            content on the platform, including works, pictures, archives,
            information, materials, platform architecture, the arrangement of
            the platform screen, platform design, text and graphics, software
            compilation, the relevant source code and software, are legally
            owned by the Platform or other rights-holders. Article 88 Without
            the written consent of the Platform or other rights holders, no one
            is allowed to use, modify, copy, publicly disseminate, change,
            distribute, release or publicly publish the Platform program or
            content without due authorization. Article 89 The Users are not
            allowed to download (except for web page caches) or modify the
            Platform or any part thereof without the explicit written consent of
            the Platform. The Users are not allowed to resell or make commercial
            use of the Platform or any of content thereof; the Users may not:
            collect and use product catalogues, descriptions and prices, make
            any derivatives of the Platform or content thereof; download or copy
            account information or use any data collection robots or similar
            data collection and extraction tools for other commercial interests.
            Without the written permission of the Platform, it is strictly
            prohibited to systematically obtain the content of the Platform to
            directly or indirectly create or edit collections, compilations,
            databases, or personal name and address records (whether or not
            through robots, spiders, automatic instruments, or manual
            operations). In addition, it is strictly prohibited to use the
            content and materials on the Platform for any purpose that is not
            explicitly permitted by the terms of use. Article 90 Without the
            explicit written consent of the Platform, the Platform website or
            any part thereof may not be copied, photocopied, duplicated, sold,
            resold, accessed or otherwise used for any commercial purpose.
            Without the explicit written consent of the Platform, the Users are
            not allowed to use any technique to acquire any of the trademarks,
            logos or other proprietary information (including images, text, web
            designs or forms) of the Platform or affiliated companies thereof.
            Without the explicit written consent of the Platform, Users are not
            allowed to use the name or trademark of the Platform or affiliated
            companies thereof in the form of meta tags or any other "hidden
            text". Any such unauthorized use will result in termination of the
            permit or license granted by the Platform. Article 91 Neither the
            Users’ logging into the Platform nor their use of any service
            provided by the Platform shall be deemed as the transfer of any
            intellectual property rights from the Platform to Users. The Users
            are subject to the obligation to respect intellectual property
            rights, and should the Users infringe on any of the intellectual
            property rights, the User shall bear legal liabilities to the
            Platform, including indemnifying the Platform against damages that
            may arise therefrom.
            <h1 className="mt-5 font-bold"> Chapter 15 General Provisions </h1>
            Article 92 This Agreement is jointly signed by the Users and the
            Platform and is applicable to all activities of the Users on the
            Platform. The content of this Agreement includes the terms and
            conditions of the main body of the Agreement and various rules that
            have been issued or may be issued in the future. All the terms,
            conditions, and rules are an integral part of this Agreement and
            shall have the same legal effect as the main body of this Agreement.
            Article 93 If any term or condition of this Agreement is deemed to
            be unenforceable, invalid or illegal by any competent authority, the
            validity of the other terms and conditions of this Agreement shall
            not be affected. Article 94 The rights and obligations agreed on in
            this Agreement shall also be binding on the transferees, heirs,
            executors and administrators of all parties hereto that have
            obtained benefits from the rights and obligations through transfer
            or assignment of such rights and obligations. The Users may not
            transfer their rights or obligations under this Agreement to any
            third party without the consent of the Platform, but the Platform
            may transfer its rights and obligations under this Agreement to any
            third party at any time, with a notice to the Users thirty (30) days
            prior to the transfer. Article 95 If any term or condition in this
            Agreement becomes totally or partially invalid or unenforceable for
            any reason, it shall be deemed that the term or condition can be
            separated from this Agreement and replaced by a new and effective
            term or condition that is as close as possible to the intentions of
            the parties and that can preserve the economic purposes required by
            this Agreement. Moreover, in this case, the other terms and
            conditions of this Agreement shall remain fully valid and binding.
            Article 96 Unless it is otherwise agreed in this Agreement, nothing
            in this Agreement shall be deemed to have created, implied or
            otherwise treated the Platform as an agent, trustee or other
            representative of the Users. Article 97 The failure of either party
            hereto to exercise any of the rights thereof hereunder or seek
            remedies in connection with a single event does not affect the
            subsequent exercise of such rights or seeking of remedies in
            connection with such event or other events. Article 98 Waiver of
            breach of agreement or waiver of any term or condition of this
            Agreement shall take effect only after the non-breaching party or
            the party that does not seek such waiver signs in writing to agree
            to the waiver. Any waiver of breach of agreement under this
            Agreement cannot be deemed or interpreted as an exemption of the
            non-breaching party from any subsequent breach of agreement or other
            breach of agreement. Failure to exercise any right or remedy shall
            not be construed as a waiver of such right or remedy in any way.
            Article 99 In executing this Agreement, the User agrees that all of
            the activities that the Platform conducts with the User takes place
            within the Republic of Seychelles, regardless of the physical
            location at the time the User uses the Platform or otherwise engages
            with Platform. This means that the User agrees that the parties
            activities are subject only to the laws of Seychelles and not to the
            laws of any other country in which the User may be physically
            present at any given time, and therefore that any redress the User
            may seek from the Platform in any circumstances must be sought under
            Seychelles law. However, the User agrees to comply with all relevant
            laws and regulations that may apply to Users’ application of the
            Platform in any jurisdiction in which the User may be located.
            Article 100 The Platform is not offering its services outside the
            Republic of Seychelles. However, a User does not need to be resident
            in the Seychelles to be eligible to utilize the services of the
            Platform. Foreign Users themselves are wholly responsible for
            complying with all laws in their respective country of residence or
            from which they may access the Platform and the Platform accepts no
            responsibility for any breach of any local laws. Where a
            jurisdiction has relevant laws which regulate digital asset trading,
            no offer to provide Platform services is made to such foreign Users
            where it would constitute a breach of any law or require any filing,
            registration or approval with any competent authority. Article 101
            This Agreement is concluded in accordance with the laws of the
            Republic of Seychelles, and its establishment, interpretation,
            content and performance shall be governed by the relevant laws and
            regulations of the Republic of Seychelles. Any claim or lawsuit
            arising out of or in connection with this Agreement shall be
            interpreted and enforced by the laws of the Republic of Seychelles.
            Article 102 Unless otherwise agreed by other rules under this
            Agreement, both parties agree that any claims or lawsuits arising
            from or related to this Agreement shall be submitted to the
            Singapore International Arbitration Commission for arbitration.
            Article 103 This Agreement shall enter into force when the Users
            obtain their account with this Website, and shall be binding on the
            Users and this Platform. Article 104 The ultimate power to interpret
            this Agreement shall be vested in this Platform.
          </div>
        </div>
      ) : (
        <section className="bg-black w-screen h-screen overflow-x-hidden scroll-smooth hover:scroll-auto snap-y">
          <nav className="flex flex-row fixed">
            <div
              className="flex cursor-pointer"
              onClick={() => setSignUp(false)}
            >
              <img
                alt="logo"
                src={logo}
                className="w-[50px] h-[50px] mr-[-2px] m-7"
              />
              <p className="text-white/40 py-[43px] text-xl font-serif">
                EXONYX
              </p>
            </div>
            <ul className="flex flex-row text-gray-300/50  gap-x-10 py-[47px] pl-10">
              <li
                onClick={() => setTermOpen(true)}
                className="hover:text-gray-300 cursor-pointer text-sm font-serif"
              >
                Terms of use and Privacy
              </li>
              <li
                onClick={() => setSignUp(true)}
                className="hover:text-gray-300 cursor-pointer text-sm font-serif"
              >
                Login/Register
              </li>
            </ul>
          </nav>
          {signUp === true ? (
            <div className="bg-transparent w-screen h-screen flex items-center justify-center flex-col">
              <h1
                className={`text-white text-2xl mb-10 ${
                  showOTP === true ? "opacity-0" : ""
                }`}
              >
                {" "}
                Sign Up
              </h1>
              <input
                className={`border border-white bg-transparent text-white px-8 py-2 mt-10 focus:rounded-none ${
                  showOTP === true ? "opacity-0" : ""
                }`}
                type="email"
                placeholder="Email"
              />
              <input
                className={`border border-white bg-transparent text-white px-8 py-2 my-10 focus:rounded-none ${
                  showOTP === true ? "opacity-0" : ""
                }`}
                type="text"
                placeholder="Full Name"
              />
              {showOTP === true && (
                <button
                  onClick={() => setErr(true)}
                  className={`bg-transparent border text-white/50 px-4 py-2 absolute translate-y-[130px] rounded`}
                >
                  Submit OTP
                </button>
              )}
              <button
                className={`bg-transparent border text-white/50 px-4 py-2 my-10 rounded ${
                  showOTP === true ? "opacity-0" : ""
                }`}
                onClick={() => {
                  setShowOTP(true);
                }}
              >
                Submit
              </button>
              {showOTP && (
                <input
                  className={`border border-white bg-transparent text-white px-4 py-2 absolute focus:rounded-none ${
                    err === true ? "border-red-300" : ""
                  }`}
                  type="text"
                  placeholder="Enter OTP"
                />
              )}
              {err === true ? (
                <p className="text-red-300 absolute translate-y-10">
                  OTP is not valid
                </p>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className={`${signUp === true ? "" : ""}`}>
              <div className="snap-center mt-[140px]">
                <img
                  src={mock1}
                  alt="mock1"
                  className=" mx-auto opacity-50 w-[500px]"
                />
                <h1 className=" text-center text-[80px] text-transparent bg-clip-text bg-gradient-to-b from-gray-50 tracking-wide font-serif translate-y-[-500px]">
                  Trade Crypto super easy
                </h1>
              </div>
              {/* second */}
              <div className="snap-center flex flex-row justify-center mt-[300px]">
                <img src={mock2} alt="mock2" className="opacity-90 w-[300px]" />
                <div className="ml-10">
                  <h1 className="text-[30px] mt-[200px] text-white tracking-wide font-serif ">
                    With live markets
                  </h1>
                  <h2 className=" text-[20px]  text-white/50 tracking-wide font-serif ">
                    Be aware of every movment
                  </h2>
                </div>
              </div>
              {/* third */}
              <div className="snap-center flex flex-row justify-center mt-[300px]">
                <div className=" mr-16 mt-[250px]">
                  <h1 className="text-[30px] text-white tracking-wide font-serif ">
                    Money exchange made easy
                  </h1>
                  <h2 className=" text-[20px] text-white/50 tracking-wide font-serif ">
                    the way it should be!
                  </h2>
                </div>{" "}
                <img src={mock3} alt="mock3" className="opacity-90 w-[300px]" />
              </div>
              {/* wide */}
              <img
                src={txt}
                alt="txt"
                className="mx-auto mt-[320px] pr-20 rotate-[-18deg]"
              />
              <div className="flex flex-row justify-center">
                <img src={rec} alt="mock3" className="" />
              </div>{" "}
              <footer className="text-white/50 p-10 h-[100px] mt-[400px] bg-neutral-950 px-[800px]">
                <hr className="opacity-20 mb-3" />
                <p className="mx-auto text-center">
                  {" "}
                  © 2023 EXONYX Inc. | info@exonyx.email
                </p>
              </footer>
            </div>
          )}
          {/* first */}
        </section>
      )}
    </>
  );
}

export default App;
